.blue-heading-main {
    color: #113689;
    font-size: 22px;
    font-weight: 600;
    @media (max-width: 1699px) and (min-width: 1400px) {
        font-size: 20px;
    }

    @media (max-width: 1399px) and (min-width: 1199px) {
        font-size: 18px;
    }
}

.bg-secondry-gray{background: #F2F2F2;}



.tab-for-graph {
    .heading-sm1 {
        letter-spacing: 0.14px;
        color: #113689;
        font-size: 24px;
        font-weight: 600;
        margin: 0 15px 0 0;

        @media (max-width:1599px) and (min-width: 1361px) {
            font-size: 20px;
        }

        @media (max-width:1360px) and (min-width: 1024px) {
            font-size: 18px;
        }
    }

    .view-data-tab{
        .nav-tabs .nav-link {            
            padding: 7px 20px;

            @media (max-width:1599px) and (min-width: 1361px) {
                padding: 7px 12px;
            }
    
            @media (max-width:1360px) and (min-width: 1024px) {
                padding: 7px 12px;
            }
    
        }
    }

    .nav-tabs .nav-link {
        color: #212121;
        font-size: 15px;
        padding: 15px 30px;
        background: transparent;
        margin-bottom: 8px;
        border: 1px solid #E2E2E2;
        margin-right: 0px;
        border-radius: 40px;

        @media (max-width:1599px) and (min-width: 1361px) {
            font-size: 13px;
            padding: 10px 15px;
        }

        @media (max-width:1360px) and (min-width: 1024px) {
            font-size: 12px;
            padding: 8px 15px;
        }

    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #ffffff;
        background-color: #000;
        border-color: #000;
        border-radius: 40px;
    }

    .customize-btn {
        background: #E2E2E2;
        color: #000;
        font-weight: 600;
        font-size: 15px;
        padding: 8px 20px;
        float: right;
        margin-top: 15px;

        @media (max-width:1599px) and (min-width: 1361px) {
            font-size: 14px;
            margin-top: 12px;
        }

        @media (max-width:1360px) and (min-width: 1024px) {
            font-size: 12px;
            margin-top: 7px;
            padding: 7px 15px;
        }

        span.material-icons-round {
            font-size: 20px;
            margin-right: 5px;
            color: #254895;
        }
    }

    .tab-text-infra.download-rep {
        display: flex;
        align-items: center;
        cursor: pointer;

        span.material-icons-round {
            border: 1px solid #444;
            border-radius: 10px;
            padding: 4px;
            font-size: 20px;
            margin-left: 5px;
        }
    }


    .report-inner-tab {
        .nav-tabs {
            background: #fff;
            display: inline-flex;
            padding: 8px 15px;
            border-radius: 40px;
        }

        .nav-tabs .nav-link {
            background: #fff;
            border: 1px solid #fff;
            margin-bottom: 0px;
            padding: 7px 20px;
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            color: #ffffff;
            background-color: #113689;
            border-color: #113689;
        }
    }

    .about-card {
        background: #fff;
        padding: 25px;
        border-radius: 15px;
    }

    .heading-sm2 {
        font-size: 24px;
    }

    .btn-add-more {
        background: #F7EDD6;
        color: #FABF38;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 5px;
        cursor: pointer;
    }

    table tr th {
        color: #212121 !important;
        font-weight: 600 !important;
        padding: 12px 15px !important;
        white-space: nowrap;
    }

    .text-blue {
        color: #254895;
    }

    .heading-sm.heading-sm2 {
        font-size: 19px;
        display: flex;
        align-items: center;
        font-weight: 600;

        @media (max-width:1599px) and (min-width: 1024px) {
            font-size: 16px !important;
        }
    }

    .filter-card {
        background: #fff;
        padding: 15px 20px 20px;
        border-radius: 20px;

        input.form-control.border-only-bottom {
            border: 0px;
            border-bottom: 1px solid #b2b1b1 !important;
            border-radius: 0;
            padding-left: 0;
        }

        .heading-sm1 {
            letter-spacing: 0.14px;
            color: #000000;
            font-size: 18px;
            font-weight: 600;
            margin: 0 15px 0 0;

            @media (max-width:1599px) and (min-width: 1024px) {
                font-size: 15px;
            }

        }

        .indicator-select {
            label.bg-grey2 {
                background: transparent;
                top: 3px;
                color: #000;

                @media (max-width:1599px) and (min-width: 1024px) {
                    left: 9px;
                    top: 8px;
                }
            }

            .form-select {
                height: 50px;
                border-radius: 35px;
                padding-top: 12px;
                font-weight: 600;
                appearance: auto;
                padding-left: 25px;
                padding-right: 10px;

                @media (max-width:1599px) and (min-width: 1024px) {
                    padding-left: 15px;
                }

            }
        }

        button.header-dropdown-btn {
            width: 100%;
            justify-content: center;
            font-size: 16px;
            @media (max-width:1599px) and (min-width: 1024px) {
                font-size: 14px;
            }
        }
    }

    a.action-icon {
        margin-right: 10px;
        border: 1px solid #696969;
        border-radius: 40px;
        vertical-align: middle;
        display: inline-block;
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 26px;

        img {
            width: 16px;
        }
    }


}

.custmize-filter-column {
    background: #fff;
    border-radius: 10px 0 0 10px;
    bottom: 10%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .102);
    min-height: 250px;
    opacity: 0;
    padding: 0;
    position: fixed;
    right: 0;
    transform: rotateY(90deg);
    transform-origin: right;
    transition: all .5s ease-in-out;
    width: 350px;
    z-index: 9;

    @media (max-width:1599px) and (min-width: 1361px) {
        bottom: 12%;
    }

    @media (max-width:1360px) and (min-width: 1024px) {
        bottom: 10%;
    }

    &.show {
        opacity: 1;
        transform: rotateY(0deg);
        transition: all 500ms ease-in-out;
    }

    .form-group.search .form-control {
        background: #F2F2F2;
        height: 50px;
        border-radius: 50px;
        margin-top: 15px;

        @media (max-width:1599px) and (min-width: 1024px) {
            height: 40px;
        }
    }

    .form-group.checkbox {
        background: #F2F2F2;
        margin: 8px 0;
        padding: 6px 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;

        @media (max-width:1599px) and (min-width: 1361px) {
            margin: 6px 0;
            padding: 6px 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        @media (max-width:1360px) and (min-width: 1024px) {
            margin: 6px 0;
            padding: 6px 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        input {
            margin-right: 10px;
            height: 14px;
            width: 14px;
        }
    }

    button.close-btn {
        background: #ffffff !important;
        height: 35px !important;
        width: 35px !important;
        border: 1px solid #dadada !important;
        border-radius: 50px !important;
        font-size: 10px !important;
        color: #000000 !important;
        font-weight: 600 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

}


table tr, table tr td {
    border-color:#efeaea !important;
}

.table-text-i button#uncontrolled-tab-example-tab-table {
    display: flex;
    align-items: center;

    &.active::before {
        filter: invert(1);
    }

    &:before {
        content: "";
        background: url('../../assets/images/table_cells.png');
        width: 15px;
        height: 15px;
        background-size: cover;
        display: block;
        margin-right: 6px;       
    }
}
.table-text-i button#uncontrolled-tab-example-tab-graph {
    display: flex;
    align-items: center;
    &.active::before {
        filter: invert(1);
    }
    &:before {
        content: "";
        background: url('../../assets/images/circular-graphic.png');
        width: 15px;
        height: 15px;
        background-size: cover;
        display: block;
        margin-right: 6px;
    }
}

.infrastructure-main-card .tab-for-graph .impact-box-content-education {
    background: #faf6ed;
    border-radius: 25px;
    margin-bottom: 20px;
    min-height: 183px;
    padding: 20px;
    position: relative;
    transition: all .3s ease-in-out;
}
.infrastructure-main-card .tab-for-graph .impact-box-content-education h2.heading-sm {
    font-size: 24px !important;
    margin-right: 0;
    font-weight: 600;
}

.p-relative{position: relative;}
.backgroung-white-bg-layer {
    background: #fff;
    height: 115px;
    position: absolute;
    top: 0;
    width: 100%;

    @media (max-width:1599px) and (min-width: 1361px) {
        height: 100px;
    }

    @media (max-width:1360px){
        height: 84px;
    }

}

.tab-for-graph ul.nav.nav-tabs{
    position:relative;
    z-index: 9;
}