/* fallback */
@font-face {
    font-family: 'Material Icons';
    src: url('../assets/fonts/MaterialIcons-Regular.eot');
    src: url('../assets/fonts/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
        url('../assets/fonts/MaterialIcons-Regular.woff') format('woff'),
        url('../assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
    
  /* fallback */
  @font-face {
      font-family: 'Material Icons Round';
      src: url('../assets/fonts/MaterialIconsRound-Regular.eot');
      src: url('../assets/fonts/MaterialIconsRound-Regular.eot?#iefix') format('embedded-opentype'),
          url('../assets/fonts/MaterialIconsRound-Regular.woff2') format('woff2'),
          url('../assets/fonts/MaterialIconsRound-Regular.woff') format('woff'),
          url('../assets/fonts/MaterialIconsRound-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
  
  /* fallback */
  @font-face {
    font-family: 'Material Icons Two Tone';
    src: url('../assets/fonts/MaterialIconsTwoTone-Regular.eot');
    src: url('../assets/fonts/MaterialIconsTwoTone-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MaterialIconsTwoTone-Regular.woff2') format('woff2'),
        url('../assets/fonts/MaterialIconsTwoTone-Regular.woff') format('woff'),
        url('../assets/fonts/MaterialIconsTwoTone-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
    
  /* fallback */
  @font-face {
    font-family: 'Material Icons Outlined';
    src: url('../assets/fonts/MaterialIconsOutlined-Regular.eot');
    src: url('../assets/fonts/MaterialIconsOutlined-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MaterialIconsOutlined-Regular.woff2') format('woff2'),
        url('../assets/fonts/MaterialIconsOutlined-Regular.woff') format('woff'),
        url('../assets/fonts/MaterialIconsOutlined-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }
    
    
    .material-icons-round {
      font-family: 'Material Icons Round';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }
    .material-icons-rounddata{
      font-family: 'Material Icons Round';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }
    
    .material-icons-two-tone {
      font-family: 'Material Icons Two Tone';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }
    
    .material-icons-outlined {
      font-family: 'Material Icons Outlined';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }